import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { register } from "@/api/userInfo";
import { hideLoading, showLoading } from "@/utils/utils";
export default defineComponent({
  name: "register",

  setup() {
    const form = ref({
      phone: "",
      password: "",
      selectedCode: "+86",
      twoPassword: "",
      invitationCode: "" // 邀请码

    });
    const router = useRouter();
    const route = useRoute(); // 获取当前路由对象

    const errors = ref({
      phone: "",
      password: "",
      twoPassword: "",
      invitationCode: ""
    });
    const showDropdown = ref(false);
    const countryCodes = ref([{
      text: "+86",
      value: "+86"
    }, {
      text: "+1",
      value: "+1"
    }, {
      text: "+44",
      value: "+44"
    }]);

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const selectCode = code => {
      form.value.selectedCode = code;
      showDropdown.value = false;
    };

    const toDetail = path => {
      router.push(path);
    };

    const validateForm = () => {
      errors.value.phone = form.value.phone ? "" : "Phone number is required";
      errors.value.password = form.value.password ? form.value.password.length >= 6 ? "" : "Password must be at least 6 characters" : "Password is required";
      errors.value.twoPassword = form.value.twoPassword ? form.value.twoPassword === form.value.password ? "" : "Passwords do not match" : "Confirm password is required";
      return !errors.value.phone && !errors.value.password && !errors.value.twoPassword && !errors.value.invitationCode;
    };

    const onSubmit = async () => {
      if (validateForm()) {
        const fullPhone = form.value.selectedCode.replace("+", "") + form.value.phone;

        try {
          showLoading(); // 显示加载状态

          const response = await register({
            loginName: fullPhone,
            password: form.value.password,
            inviterCode: form.value.invitationCode,
            userName: form.value.phone // 使用手机号码作为用户名

          });
          hideLoading(); // 隐藏加载状态

          if (response.code == 1) {
            _Toast.success("Registration successful!"); // 注册成功提示


            setTimeout(() => {
              router.push("/login");
            }, 2000); // 2秒后跳转到登录页面
          } else {
            _Toast.fail(response.msg || "Unknown error"); // 注册失败提示

          }
        } catch (error) {
          hideLoading(); // 隐藏加载状态

          console.error("注册失败", error);
          errors.value.phone = "Network error, please try again";
        }
      } else {
        console.log("Validation failed, please check the form.");
      }
    };

    onMounted(() => {
      const invitationCode = route.query.inviterCode; // 获取邀请码参数

      if (invitationCode) {
        form.value.invitationCode = invitationCode; // 回填邀请码
      }
    });
    return {
      form,
      errors,
      showDropdown,
      countryCodes,
      toggleDropdown,
      selectCode,
      onSubmit,
      toDetail
    };
  }

});